import React from "react";
import { useMediaQuery } from 'react-responsive';
import ReactPlayer from 'react-player';


import { 
    Grid,
    Header
} from 'semantic-ui-react';

import Martie from '../../utils/videos/Martie.mp4'

import { 
    primaryFontFamilyBold,
    mediumSize2

} from "../../utils/fonts";

import { 
    mediumSize, 
    headingFontSize2, 
    mediumSize3 
} from "../../utils/fonts";


const HerosectionLg = () => {

    const md = useMediaQuery({ query: "(min-width:700px)" })
    const xxl = useMediaQuery({ query: "(min-width:1100px)" })
    const xxl2 = useMediaQuery({ query: "(max-width:1100px)" })
    const md3 = useMediaQuery({ query: "(max-width:800px)" })

    return (
        <Grid style={{width: '100%'}}>
            <Grid.Row columns={2} >
                <Grid.Column >
                    <Header as={md3 ? 'h2' : 'h1'} style={{ fontFamily: primaryFontFamilyBold }}>
                        Sigur - toată lumea spune povești. Dar puține povești se spun pe loc și puțini îți fac loc în povestea lor. 
                    </Header>
                    <p style={{ fontSize: xxl2 ? headingFontSize2 : md ? mediumSize2 : md3 ? mediumSize3 : mediumSize }}>
                        În lumi tot mai deconectate, am deschis un spațiu independent în <strong> Turnul Porții (din Mai 2022) </strong>, în care să facem lucrurile aici și acum. La <strong> Incubator 13 </strong> improvizăm lumi noi în <strong> KaBoom Unlimited Impro </strong>, aducem muzică bună care nu trebuie să scape ascultării, punem culori pe pânză și pe hârtie, completăm educația formală și o regândim prin ateliere practice prin proiectul  <strong> ExplozivART </strong> , mergem în locuri mai puțin explorate, prin spectacole de teatru diverse tematic. 
                        <br/> Toate astea împreună cu tine. 
                        <br/> Din 2018, spunem lucruri pe care le simțim importante. Și încă mai avem multe de spus.

                    </p>
                </Grid.Column>

                <Grid.Column className="center aligned" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <ReactPlayer 
                        url={Martie} 
                        height={ xxl ? 850 : 500 } 
                        width={ xxl ? 550 : 500 } 
                        // controls={true}
                        playing={true}
                        loop={true}
                        muted={true}
                        onReady={() => true}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default HerosectionLg;