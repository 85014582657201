import K1afis from './images/allShows/Kaboom1/afis.jpg';
import K2afis from './images/allShows/Kaboom2/afis.jpg';
import K3afis from './images/allShows/Kaboom3/afis.jpg';
import K4afis from './images/allShows/Kaboom4/afis.jpg';
import K5afis from './images/allShows/Kaboom5/afis.jpg';
import K6afis from './images/allShows/Kaboom6/afis.jpg';
import K7afis from './images/allShows/Kaboom7/afis.jpg';
import K8afis from './images/allShows/Kaboom8/afis.jpg';
import K9afis from './images/allShows/Kaboom9/afis.jpg';
import K10afis from './images/allShows/Kaboom10/afis.jpg';
import K11afis from './images/allShows/Kaboom11/afis.jpg';
import K12afis from './images/allShows/Kaboom12/afis.jpg';
import K13afis from './images/allShows/Kaboom13/afis.jpg';
import K14afis from './images/allShows/Kaboom14/afis.jpg';
import K15afis from './images/allShows/Kaboom15/afis.jpg';
import K16afis from './images/allShows/Kaboom16/afis.jpg';
import K17afis from './images/allShows/Kaboom17/afis.jpg';
import K18afis from './images/allShows/Kaboom18/afis.jpg';
import K19afis from './images/allShows/Kaboom19/afis.jpg';
import K20afis from './images/allShows/Kaboom20/afis.jpg';
import K21afis from './images/allShows/Kaboom21/afis.jpg';
import afisExploziv1 from './images/allShows/exploziv1/afis.jpg';
import afisIubiri from './images/allShows/miiIubiri/afis.png';
import Kaboom27 from './images/Kaboom27.jpg'
import OpenMic from './images/OpenMic.jpg'
import change from './images/change.jpg'

import K1img1 from './images/allShows/Kaboom1/1.jpg';
import K1img2 from './images/allShows/Kaboom1/2.jpg';
import K1img3 from './images/allShows/Kaboom1/3.jpg';
import K1img4 from './images/allShows/Kaboom1/4.jpg';
import K1img5 from './images/allShows/Kaboom1/5.jpg';
import K1img6 from './images/allShows/Kaboom1/6.jpg';
import K1img7 from './images/allShows/Kaboom1/7.jpg';

import K2img1 from './images/allShows/Kaboom2/1.jpg';
import K2img2 from './images/allShows/Kaboom2/2.jpg';
import K2img3 from './images/allShows/Kaboom2/3.jpg';
import K2img4 from './images/allShows/Kaboom2/4.jpg';
import K2img5 from './images/allShows/Kaboom2/5.jpg';
import K2img6 from './images/allShows/Kaboom2/6.jpg';
import K2img7 from './images/allShows/Kaboom2/7.jpg';

import K3img1 from './images/allShows/Kaboom3/1.jpg';
import K3img2 from './images/allShows/Kaboom3/2.jpg';
import K3img3 from './images/allShows/Kaboom3/3.jpg';
import K3img4 from './images/allShows/Kaboom3/4.jpg';
import K3img5 from './images/allShows/Kaboom3/5.jpg';
import K3img6 from './images/allShows/Kaboom3/6.jpg';
import K3img7 from './images/allShows/Kaboom3/7.jpg';

import K4img1 from './images/allShows/Kaboom4/1.jpg';
import K4img2 from './images/allShows/Kaboom4/2.jpg';
import K4img3 from './images/allShows/Kaboom4/3.jpg';
import K4img4 from './images/allShows/Kaboom4/4.jpg';
import K4img5 from './images/allShows/Kaboom4/5.jpg';
import K4img6 from './images/allShows/Kaboom4/6.jpg';
import K4img7 from './images/allShows/Kaboom4/7.jpg';

import K5img1 from './images/allShows/Kaboom5/1.jpg';
import K5img2 from './images/allShows/Kaboom5/2.jpg';
import K5img3 from './images/allShows/Kaboom5/3.jpg';
import K5img4 from './images/allShows/Kaboom5/4.jpg';
import K5img5 from './images/allShows/Kaboom5/5.jpg';
import K5img6 from './images/allShows/Kaboom5/6.jpg';
import K5img7 from './images/allShows/Kaboom5/7.jpg';

import K6img1 from './images/allShows/Kaboom6/1.jpg';
import K6img2 from './images/allShows/Kaboom6/2.jpg';
import K6img3 from './images/allShows/Kaboom6/3.jpg';
import K6img4 from './images/allShows/Kaboom6/4.jpg';
import K6img5 from './images/allShows/Kaboom6/5.jpg';
import K6img6 from './images/allShows/Kaboom6/6.jpg';
import K6img7 from './images/allShows/Kaboom6/7.jpg';

import K7img1 from './images/allShows/Kaboom7/1.jpg';
import K7img2 from './images/allShows/Kaboom7/2.jpg';
import K7img3 from './images/allShows/Kaboom7/3.jpg';
import K7img4 from './images/allShows/Kaboom7/4.jpg';
import K7img5 from './images/allShows/Kaboom7/5.jpg';
import K7img6 from './images/allShows/Kaboom7/6.jpg';
import K7img7 from './images/allShows/Kaboom7/7.jpg';

import K8img1 from './images/allShows/Kaboom8/1.jpg';
import K8img2 from './images/allShows/Kaboom8/2.jpg';
import K8img3 from './images/allShows/Kaboom8/3.jpg';
import K8img4 from './images/allShows/Kaboom8/4.jpg';
import K8img5 from './images/allShows/Kaboom8/5.jpg';
import K8img6 from './images/allShows/Kaboom8/6.jpg';
import K8img7 from './images/allShows/Kaboom8/7.jpg';

import K9img1 from './images/allShows/Kaboom9/1.jpg';
import K9img2 from './images/allShows/Kaboom9/2.jpg';
import K9img3 from './images/allShows/Kaboom9/3.jpg';
import K9img4 from './images/allShows/Kaboom9/4.jpg';
import K9img5 from './images/allShows/Kaboom9/5.jpg';
import K9img6 from './images/allShows/Kaboom9/6.jpg';
import K9img7 from './images/allShows/Kaboom9/7.jpg';

import K10img1 from './images/allShows/Kaboom10/1.jpg';
import K10img2 from './images/allShows/Kaboom10/2.jpg';
import K10img3 from './images/allShows/Kaboom10/3.jpg';
import K10img4 from './images/allShows/Kaboom10/4.jpg';
import K10img5 from './images/allShows/Kaboom10/5.jpg';
import K10img6 from './images/allShows/Kaboom10/6.jpg';
import K10img7 from './images/allShows/Kaboom10/7.jpg';

import K11img1 from './images/allShows/Kaboom11/1.jpg';
import K11img2 from './images/allShows/Kaboom11/2.jpg';
import K11img3 from './images/allShows/Kaboom11/3.jpg';
import K11img4 from './images/allShows/Kaboom11/4.jpg';
import K11img5 from './images/allShows/Kaboom11/5.jpg';
import K11img6 from './images/allShows/Kaboom11/6.jpg';
import K11img7 from './images/allShows/Kaboom11/7.jpg';

import K12img1 from './images/allShows/Kaboom12/1.jpg';
import K12img2 from './images/allShows/Kaboom12/2.jpg';
import K12img3 from './images/allShows/Kaboom12/3.jpg';
import K12img4 from './images/allShows/Kaboom12/4.jpg';
import K12img5 from './images/allShows/Kaboom12/5.jpg';
import K12img6 from './images/allShows/Kaboom12/6.jpg';
import K12img7 from './images/allShows/Kaboom12/7.jpg';

import K13img1 from './images/allShows/Kaboom13/1.jpg';
import K13img2 from './images/allShows/Kaboom13/2.jpg';
import K13img3 from './images/allShows/Kaboom13/3.jpg';
import K13img4 from './images/allShows/Kaboom13/4.jpg';
import K13img5 from './images/allShows/Kaboom13/5.jpg';
import K13img6 from './images/allShows/Kaboom13/6.jpg';
import K13img7 from './images/allShows/Kaboom13/7.jpg';

import K14img1 from './images/allShows/Kaboom14/1.jpg';
import K14img2 from './images/allShows/Kaboom14/2.jpg';
import K14img3 from './images/allShows/Kaboom14/3.jpg';
import K14img4 from './images/allShows/Kaboom14/4.jpg';
import K14img5 from './images/allShows/Kaboom14/5.jpg';
import K14img6 from './images/allShows/Kaboom14/6.jpg';
import K14img7 from './images/allShows/Kaboom14/7.jpg';

import K15img1 from './images/allShows/Kaboom15/1.jpg';
import K15img2 from './images/allShows/Kaboom15/2.jpg';
import K15img3 from './images/allShows/Kaboom15/3.jpg';
import K15img4 from './images/allShows/Kaboom15/4.jpg';
import K15img5 from './images/allShows/Kaboom15/5.jpg';
import K15img6 from './images/allShows/Kaboom15/6.jpg';
import K15img7 from './images/allShows/Kaboom15/7.jpg';

import K16img1 from './images/allShows/Kaboom16/1.jpg';
import K16img2 from './images/allShows/Kaboom16/2.jpg';
import K16img3 from './images/allShows/Kaboom16/3.jpg';
import K16img4 from './images/allShows/Kaboom16/4.jpg';
import K16img5 from './images/allShows/Kaboom16/5.jpg';
import K16img6 from './images/allShows/Kaboom16/6.jpg';
import K16img7 from './images/allShows/Kaboom16/7.jpg';

import K17img1 from './images/allShows/Kaboom17/1.jpg';
import K17img2 from './images/allShows/Kaboom17/2.jpg';
import K17img3 from './images/allShows/Kaboom17/3.jpg';
import K17img4 from './images/allShows/Kaboom17/4.jpg';
import K17img5 from './images/allShows/Kaboom1/5.jpg';
import K17img6 from './images/allShows/Kaboom17/6.jpg';
import K17img7 from './images/allShows/Kaboom17/7.jpg';

import K18img1 from './images/allShows/Kaboom18/1.jpg';
import K18img2 from './images/allShows/Kaboom18/2.jpg';
import K18img3 from './images/allShows/Kaboom18/3.jpg';
import K18img4 from './images/allShows/Kaboom18/4.jpg';
import K18img5 from './images/allShows/Kaboom18/5.jpg';
import K18img6 from './images/allShows/Kaboom18/6.jpg';
import K18img7 from './images/allShows/Kaboom18/7.jpg';

import K19img1 from './images/allShows/Kaboom19/1.jpg';
import K19img2 from './images/allShows/Kaboom19/2.jpg';
import K19img3 from './images/allShows/Kaboom19/3.jpg';
import K19img4 from './images/allShows/Kaboom19/4.jpg';
import K19img5 from './images/allShows/Kaboom19/5.jpg';
import K19img6 from './images/allShows/Kaboom19/6.jpg';
import K19img7 from './images/allShows/Kaboom19/7.jpg';


import K20img1 from './images/allShows/Kaboom20/1.jpg';
import K20img2 from './images/allShows/Kaboom20/2.jpg';
import K20img3 from './images/allShows/Kaboom20/3.jpg';
import K20img4 from './images/allShows/Kaboom20/4.jpg';
import K20img5 from './images/allShows/Kaboom20/5.jpg';
import K20img6 from './images/allShows/Kaboom20/6.jpg';
import K20img7 from './images/allShows/Kaboom20/7.jpg';

import Exploziv1 from './images/allShows/exploziv1/1.jpg';
import Exploziv2 from './images/allShows/exploziv1/2.jpg';
import Exploziv3 from './images/allShows/exploziv1/3.jpg';
import Exploziv4 from './images/allShows/exploziv1/4.jpg';
import Exploziv5 from './images/allShows/exploziv1/5.jpg';
import Exploziv6 from './images/allShows/exploziv1/6.jpg';
import Exploziv7 from './images/allShows/exploziv1/7.jpg';

import K32afis from './images/K32afis.jpg'
import K23 from './images/spectacoleViitoare/Kaboom23.jpg'
import Gol from './images/gol.jpg';
import NoapteaMuzeelor from './images/NoapteaMuzeelor.jpg'
import ExplozivNou from './images/ExplozivNou.jpg'
import Logo from './GeneralImages/Logo.png'
import Vagin1 from './images/allShows/vagin/vagin1.jpg'
import Vagin2 from './images/allShows/vagin/vagin2.jpg'
import logoKaboom2 from './images/logoKaboom2.jpg'
import afisKaboom24 from './images/afisKaboom24.jpg'
import zgomotul from './images/zgomotul.jpg'
import monoloage from './images/monoloage.jpg'
import Kaboom262 from './images/Kaboom26part2.jpg'
import Kaboom31 from './images/Kaboom31.jpg'
import Bikeathon from './images/Bikeathon.jpg';
import Tango from './images/Tango.jpg'
import Kaboom28 from './images/Kaboom28.jpg'
import Kaboom30 from './images/Kaboom30.jpg'
import PisicaVerde from './images/PisicaVerde.jpg'
import PisicaVerdeN from './images/PisicaVerdeN.jpg'
import concert from './images/concert.jpg';
import Elise23 from './images/Elise23.jpg';
import Concert23 from './images/concert23.png'
import K33 from './images/K33.jpg'
import Suspendat from './images/suspendat16.jpg'
import exp from './images/expnou.jpg'
import K34 from './images/K34.jpg'
import Improteka from './images/Improteka.jpg'
import Suspendat6 from './images/Suspendat6.jpg';
import K35 from './images/K35.jpg';
import Exploziv27 from './images/Exploziv27.jpg'

export const allShows = [
    {
      id: 1,
      src: K1afis,
      title: 'KaBoom Unlimited IMPRO 1 ',
      address: 'Turnul portii, Cetatea medievală',
      hour: '20:00',
      date: '16',
      month: 'JUN',
      price: 'Suport Artisti: 30RON',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
      gallery: [ 
        {
            id:1,
            src: K1img1
        },
        {
            id:2,
            src: K1img2
        },
        {
            id:3,
            src: K1img3
        },
        {
            id:4,
            src: K1img4
        },
        {
            id:5,
            src: K1img5
        },
        {
            id:6,
            src: K1img6
        },
        {
            id:7,
            src: K1img7
        },
      ]
    },
    {
      id: 2,
      src: K2afis,
      title: 'KaBoom Unlimited IMPRO 2 ',
      address: 'Turnul portii, Cetatea medievală',
      hour: '20:00',
      date: '28',
      month: 'IULIE',
      price: 'Suport Artisti: 30RON',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
      gallery: [ 
        {
            id:1,
            src: K2img1
        },
        {
            id:2,
            src: K2img2
        },
        {
            id:3,
            src: K2img3
        },
        {
            id:4,
            src: K2img4
        },
        {
            id:5,
            src: K2img5
        },
        {
            id:6,
            src: K2img6
        },
        {
            id:7,
            src: K2img7
        },
      ]
    },{
      id: 3,
      src: K3afis,
      title: 'KaBoom Unlimited IMPRO 3 ',
      address: 'Turnul portii, Cetatea medievală',
      hour: '20:00',
      date: '25',
      month: 'AUGUST',
      price: 'Suport Artisti: 30RON',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
      gallery: [ 
        {
            id:1,
            src: K3img1
        },
        {
            id:2,
            src: K3img2
        },
        {
            id:3,
            src: K3img3
        },
        {
            id:4,
            src: K3img4
        },
        {
            id:5,
            src: K3img5
        },
        {
            id:6,
            src: K3img6
        },
        {
            id:7,
            src: K3img7
        },
      ]
    },{
      id: 4,
      src: K4afis,
      title: 'KaBoom Unlimited IMPRO 4 ',
      address: 'Turnul portii, Cetatea medievală',
      hour: '20:00',
      date: '22',
      month: 'SEPTEMBRIE',
      price: 'Suport Artisti: 30RON',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
      gallery: [ 
        {
            id:1,
            src: K4img1
        },
        {
            id:2,
            src: K4img2
        },
        {
            id:3,
            src: K4img3
        },
        {
            id:4,
            src: K4img4
        },
        {
            id:5,
            src: K4img5
        },
        {
            id:6,
            src: K4img6
        },
        {
            id:7,
            src: K4img7
        },
      ]
    },{
      id: 5,
      src: K5afis,
      title: 'KaBoom Unlimited IMPRO 5 ',
      address: 'Turnul portii, Cetatea medievală',
      hour: '20:00',
      date: '28',
      month: 'OCTOMBRIE',
      price: 'Suport Artisti: 30RON',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
      gallery: [ 
        {
            id:1,
            src: K5img1
        },
        {
            id:2,
            src: K5img2
        },
        {
            id:3,
            src: K5img3
        },
        {
            id:4,
            src: K5img4
        },
        {
            id:5,
            src: K5img5
        },
        {
            id:6,
            src: K5img6
        },
        {
            id:7,
            src: K5img7
        },
      ]
    },{
      id: 6,
      src: K6afis,
      title: 'KaBoom Unlimited IMPRO 6 ',
      address: 'Turnul portii, Cetatea medievală',
      hour: '20:00',
      date: '17',
      month: 'NOIEMBRIE',
      price: 'Suport Artisti: 30RON',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
      gallery: [ 
        {
            id:1,
            src: K6img1
        },
        {
            id:2,
            src: K6img2
        },
        {
            id:3,
            src: K6img3
        },
        {
            id:4,
            src: K6img4
        },
        {
            id:5,
            src: K6img5
        },
        {
            id:6,
            src: K6img6
        },
        {
            id:7,
            src: K6img7
        },
      ]
    },{
      id: 7,
      src: K7afis,
      title: 'KaBoom Unlimited IMPRO 7 ',
      address: 'Turnul portii, Cetatea medievală',
      hour: '20:00',
      date: '8',
      month: 'DECEMBRIE',
      price: 'Suport Artisti: 30RON',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
      gallery: [ 
        {
            id:1,
            src: K7img1
        },
        {
            id:2,
            src: K7img2
        },
        {
            id:3,
            src: K7img3
        },
        {
            id:4,
            src: K7img4
        },
        {
            id:5,
            src: K7img5
        },
        {
            id:6,
            src: K7img6
        },
        {
            id:7,
            src: K7img7
        },
      ]
    },{
      id: 8,
      src: K8afis,
      title: 'KaBoom Unlimited IMPRO 8 ',
      address: 'Turnul portii, Cetatea medievală',
      hour: '20:00',
      date: '19',
      month: 'IANUARIE',
      price: 'Suport Artisti: 30RON',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
      gallery: [ 
        {
            id:1,
            src: K8img1
        },
        {
            id:2,
            src: K8img2
        },
        {
            id:3,
            src: K8img3
        },
        {
            id:4,
            src: K8img4
        },
        {
            id:5,
            src: K8img5
        },
        {
            id:6,
            src: K8img6
        },
        {
            id:7,
            src: K8img7
        },
      ]
    },{
      id: 9,
      src: K9afis,
      title: 'KaBoom Unlimited IMPRO 9 ',
      address: 'Turnul portii, Cetatea medievală',
      hour: '20:00',
      date: '28',
      month: 'FEBRUARIE',
      price: 'Suport Artisti: 30RON',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
      gallery: [ 
        {
            id:1,
            src: K9img1
        },
        {
            id:2,
            src: K9img2
        },
        {
            id:3,
            src: K9img3
        },
        {
            id:4,
            src: K9img4
        },
        {
            id:5,
            src: K9img5
        },
        {
            id:6,
            src: K9img6
        },
        {
            id:7,
            src: K9img7
        },
      ]
    },{
      id: 10,
      src: K10afis,
      title: 'KaBoom Unlimited IMPRO 10 ',
      address: 'Turnul portii, Cetatea medievală',
      hour: '20:00',
      date: '24',
      month: 'MARTIE',
      price: 'Suport Artisti: 30RON',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
      gallery: [ 
        {
            id:1,
            src: K10img1
        },
        {
            id:2,
            src: K10img2
        },
        {
            id:3,
            src: K10img3
        },
        {
            id:4,
            src: K10img4
        },
        {
            id:5,
            src: K10img5
        },
        {
            id:6,
            src: K10img6
        },
        {
            id:7,
            src: K10img7
        },
      ]
    },{
      id: 11,
      src: K11afis,
      title: 'KaBoom Unlimited IMPRO 11 ',
      address: 'Turnul portii, Cetatea medievală',
      hour: '19:30',
      date: '23',
      month: 'APRILIE',
      price: 'Suport Artisti: 30RON',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
      gallery: [ 
        {
            id:1,
            src: K11img1
        },
        {
            id:2,
            src: K11img2
        },
        {
            id:3,
            src: K11img3
        },
        {
            id:4,
            src: K11img4
        },
        {
            id:5,
            src: K11img5
        },
        {
            id:6,
            src: K11img6
        },
        {
            id:7,
            src: K11img7
        },
      ]
    },{
      id: 12,
      src: K12afis,
      title: 'KaBoom Unlimited IMPRO 12 ',
      address: 'Turnul portii, Cetatea medievală',
      hour: '19:30',
      date: '25',
      month: 'MAI',
      price: 'Suport Artisti: 30RON',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
      gallery: [ 
        {
            id:1,
            src: K12img1
        },
        {
            id:2,
            src: K12img2
        },
        {
            id:3,
            src: K12img3
        },
        {
            id:4,
            src: K12img4
        },
        {
            id:5,
            src: K12img5
        },
        {
            id:6,
            src: K12img6
        },
        {
            id:7,
            src: K12img7
        },
      ]
    },{
      id: 13,
      src: K13afis,
      title: 'KaBoom Unlimited IMPRO 13 ',
      address: 'Turnul portii, Cetatea medievală',
      hour: '19:30',
      date: '25',
      month: 'IUNIE',
      price: 'Suport Artisti: 30RON',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
      gallery: [ 
        {
            id:1,
            src: K13img1
        },
        {
            id:2,
            src: K13img2
        },
        {
            id:3,
            src: K13img3
        },
        {
            id:4,
            src: K13img4
        },
        {
            id:5,
            src: K13img5
        },
        {
            id:6,
            src: K13img6
        },
        {
            id:7,
            src: K13img7
        },
      ]
    },{
      id: 14,
      src: K14afis,
      title: 'KaBoom Unlimited IMPRO 14 ',
      address: 'Turnul portii, Cetatea medievală',
      hour: '19:30',
      date: '20',
      month: 'IULIE',
      price: 'Suport Artisti: 30RON',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
      gallery: [ 
        {
            id:1,
            src: K14img1
        },
        {
            id:2,
            src: K14img2
        },
        {
            id:3,
            src: K14img3
        },
        {
            id:4,
            src: K14img4
        },
        {
            id:5,
            src: K14img5
        },
        {
            id:6,
            src: K14img6
        },
        {
            id:7,
            src: K14img7
        },
      ]
    },{
      id: 15,
      src: K15afis,
      title: 'KaBoom Unlimited IMPRO 15 ',
      address: 'Turnul portii, Cetatea medievală',
      hour: '19:30',
      date: '24',
      month: 'AUGUST',
      price: 'Suport Artisti: 30RON',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
      gallery: [ 
        {
            id:1,
            src: K15img1
        },
        {
            id:2,
            src: K15img2
        },
        {
            id:3,
            src: K15img3
        },
        {
            id:4,
            src: K15img4
        },
        {
            id:5,
            src: K15img5
        },
        {
            id:6,
            src: K15img6
        },
        {
            id:7,
            src: K15img7
        },
      ]
    },{
      id: 16,
      src: K16afis,
      title: 'KaBoom Unlimited IMPRO 16 ',
      address: 'Turnul portii, Cetatea medievală',
      hour: '19:30',
      date: '21',
      month: 'SEPTEMBRIE',
      price: 'Suport Artisti: 30RON',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
      gallery: [ 
        {
            id:1,
            src: K16img1
        },
        {
            id:2,
            src: K16img2
        },
        {
            id:3,
            src: K16img3
        },
        {
            id:4,
            src: K16img4
        },
        {
            id:5,
            src: K16img5
        },
        {
            id:6,
            src: K16img6
        },
        {
            id:7,
            src: K16img7
        },
      ]
    },{
      id: 17,
      src: K17afis,
      title: 'KaBoom Unlimited IMPRO 17 ',
      address: 'Turnul portii, Cetatea medievală',
      hour: '19:30',
      date: '20',
      month: 'OCTOMBRIE',
      price: 'Suport Artisti: 30RON',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
      gallery: [ 
        {
            id:1,
            src: K17img1
        },
        {
            id:2,
            src: K17img2
        },
        {
            id:3,
            src: K17img3
        },
        {
            id:4,
            src: K17img4
        },
        {
            id:5,
            src: K17img5
        },
        {
            id:6,
            src: K17img6
        },
        {
            id:7,
            src: K17img7
        },
      ]
    },{
      id: 18,
      src: K18afis,
      title: 'KaBoom Unlimited IMPRO 18 ',
      address: 'Turnul portii, Cetatea medievală',
      hour: '19:30',
      date: '17',
      month: 'NOIEMBRIE',
      price: 'Suport Artisti: 30RON',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
      gallery: [ 
        {
            id:1,
            src: K18img1
        },
        {
            id:2,
            src: K18img2
        },
        {
            id:3,
            src: K18img3
        },
        {
            id:4,
            src: K18img4
        },
        {
            id:5,
            src: K18img5
        },
        {
            id:6,
            src: K18img6
        },
        {
            id:7,
            src: K18img7
        },
      ]
    },{
      id: 19,
      src: K19afis,
      title: 'KaBoom Unlimited IMPRO 19 ',
      address: 'Turnul portii, Cetatea medievală',
      hour: '19:30',
      date: '15',
      month: 'DECEMBRIE',
      price: 'Suport Artisti: 30RON',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
      gallery: [ 
        {
            id:1,
            src: K19img1
        },
        {
            id:2,
            src: K19img2
        },
        {
            id:3,
            src: K19img3
        },
        {
            id:4,
            src: K19img4
        },
        {
            id:5,
            src: K19img5
        },
        {
            id:6,
            src: K19img6
        },
        {
            id:7,
            src: K19img7
        },
      ]
    },
    {
      id: 20,
      src: K20afis,
      title: 'KaBoom Unlimited IMPRO 20 ',
      address: 'Turnul portii, Cetatea medievală',
      hour: '19:30',
      date: '26',
      month: 'JAN',
      price: 'Suport Artisti: 30RON',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
      gallery: [ 
        {
            id:1,
            src: K20img1
        },
        {
            id:2,
            src: K20img2
        },
        {
            id:3,
            src: K20img3
        },
        {
            id:4,
            src: K20img4
        },
        {
            id:5,
            src: K20img5
        },
        {
            id:6,
            src: K20img6
        },
        {
            id:7,
            src: K20img7
        },
      ]
    },
    {
        id: 21,
        src: afisExploziv1,
        title: `"Teatru Tanar - "Exploziv de Elise Wilk"`,     
        address: 'Turnul portii, Cetatea medievală',
        hour: '18:00',
        date: '4',
        month: 'FEB',
        price: 'Suport Artisti: 30RON',
        gallery: [ 
          {
              id:1,
              src: Exploziv1
          },
          {
              id:2,
              src: Exploziv2
          },
          {
              id:3,
              src: Exploziv3
          },
          {
              id:4,
              src: Exploziv4
          },
          {
              id:5,
              src: Exploziv5
          },
          {
              id:6,
              src: Exploziv6
          },
          {
              id:7,
              src: Exploziv7
          },
        ]
      },
      {
        id: 22,
        src: afisExploziv1,
        title: `"Teatru Tanar - "Exploziv de Elise Wilk"`,     
        address: 'Turnul portii, Cetatea medievală',
        hour: '18:00',
        date: '18',
        month: 'FEB',
        price: 'Suport Artisti: Adulti 30RON & Elevi 15RON',
      },
      {
        id: 23,
        src: K21afis,
        title: "KaBoom Unlimited IMPRO 21",     
        address: 'Turnul portii, Cetatea medievală',
        hour: '19:30',
        date: '23',
        month: 'FEB',
        price: 'Suport Artisti: 30RON',
        description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
      },
      {
        id: 24,
        src: afisIubiri,
        title: "Concert POE-JAZZ - Mii si Mii de Iubiri",     
        address: 'Turnul portii, Cetatea medievală',
        hour: '19:00',
        date: '24',
        month: 'FEB',
        price: 'Suport Artisti: Adulti 50RON & Elevi/Studenti 25 RON (doar 6 locuri pentru studenti/elevi)',
      },
      {
        id: 25,
        src: afisExploziv1,
        title:`"Teatru Tanar - "Exploziv de Elise Wilk"`,     
        address: 'Turnul portii, Cetatea medievală',
        hour: '18:00',
        date: '7',
        month: 'APR',
        price: 'Suport Artisti: Adulti 50RON & Elevi 30RON',
      },
      {
        id: 26,
        src: K23,
        title:`IMPRO SHOW - KABOOM UNLIMITED IMPRO 23"`,     
        address: 'Turnul portii, Cetatea medievală',
        hour: '19:30',
        date: '19',
        month: 'APR',
        price: 'Suport Artisti: 30RON',
        description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
      },{
        id: 27,
        src: Gol,
        title: 'One man show -  "GOL" cu Serban Borda',
        address: 'Turnul Portii - Cetatea medievală',
        hour: '19:30',
        date: '9',
        month: 'MAI',
        price: 'Suport Artisti: 30RON',
    },
    {
        id: 28,
        src: NoapteaMuzeelor,
        title: 'Ateliere, EXPO, jamming with the DJ - NOAPTEA MUZEELOR',
        address: 'Turnul Portii - Cetatea medievală',
        hour: '16:00 - 02:00',
        date: '18',
        month: 'MAI',
        price: '',
    },
    {
        id: 29,
        src: ExplozivNou,
        title: 'Teatru Tanar - "Exploziv" de Elise Wilk',
        address: 'Turnul Portii - Cetatea medievală',
        hour: '19:00',
        date: '19',
        month: 'MAI',
        price: 'Suport Artisti: Adulti 50RON & Elevi 30RON',
    },
    {
        id: 30,
        src: afisKaboom24,
        title: 'IMPRO SHOW - KABOOM UNLIMITED IMPRO 24 ',
        address: 'Turnul Portii - Cetatea medievală',
        hour: '20:00',
        date: '30',
        month: 'MAI',
        price: 'Suport Artisti: 30RON',
        description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
    },{
        id: 31, 
        src: ExplozivNou,
        title: `"Teatru Tanar - "Exploziv de Elise Wilk" - Ultima reprezentatie`,
        address: 'Turnul Portii - Cetatea medievală',
        hour: '19:00',
        date: '2',
        month: 'IUNIE',
        price: 'Suport Artisti: 15RON elevi & 30RON adulti',
        description: 'Exploziv – un spectacol cu și despre liceeni. Este un spectacol despre relația dintre ei, părinți și profesori, despre comunicare (sau mai degrabă lipsa ei) și despre dinamica acestor grupuri',  
        description1:'"Exploziv" este un spectacol care ar trebui văzut de toate aceste categorii și nu numai.Super bonus: veți descoperi niște tineri absolut minunați!!!',
        description2: '',
      },
      {
        id: 32,
        src: monoloage,
        title: 'Monoloagele vaginului 16+ spectacol invitat Sibiu',
        hour: '20:00',
        date: '13',
        month: 'IUNIE',
        price: 'Suport Artisti: 40RON',
        description: '"Monoloagele vaginului", dupa Eve Ensler - UN SPECTACOL INEDIT',
        description1:'Vă propunem un spectacol simplu, curat, al cărui ingredient principal este lejeritatea și finețea actoricească a celor trei protagonisteȘ Serenela Mureșan, Cristina Ragos și Codruța Vasiu. <br /> "Monoloagele vaginului" - un exercițiu actoricesc de o rară sensibilitate. O porție de umor de cea mai bună calitate. Emoție, senzualitate, feminitate.',
        description2: 'Nu vă lăsați induși în eroare de titlu. Nimic nu va fi vulgar sau la prima mână',
        gallery: [ 
            {
                id:1,
                src: Vagin1
            },
            {
                id:2,
                src: Vagin2
            },
          ]
      },
      {
        id: 33, 
        src: logoKaboom2,
        title: 'IMPRO SHOW - KABOOM UNLIMITED IMPRO 25 ',
        hour: '20:00',
        date: '25',
        month: 'IUNIE',
        price: 'Suport Artisti: 30RON copii & 40RON adulti',
        description: '',
      },
      {
        id: 34,
        src: zgomotul,
        title: 'ZGOMOTUL și FURIA  - Oigăn și Iordache',
        hour: '20:00',
        date: '28',
        month: 'IUNIE',
        price: 'Suport Artisti: 40RON',
        description: 'Zgomotul și furia este un proiect experimental demarat de Iordache și Oigăn în 2015 și care a trecut prin câteva reîncarnări foarte diferite în București, la Cluj și pe litoral.',
        description1:'Ceea ce s-a păstrat a fost latura avangardistă și relativ bizară.',
        description2: 'Zgomotul și furia a renăscut ca acțiune muzical-performativă anul acesta, prin două concerte la Zadar Bar - loc esențial al avangardei muzicale bucureștene - și la Gourmet Pub, o adevărată oază a muzicii creative din Galați. Acum suntem si in Tg Mures la INCUBATOR13',
      },
      {
        id: 35,
        src: Kaboom262,
        title: 'IMPRO SHOW - KABOOM UNLIMITED IMPRO 26 ',
        hour: '20:00',
        date: '23',
        month: 'IULIE',
        price: 'Suport Artisti: 40RON adulti, 30RON copii',
        description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
      },
      {
        id: 36,
        src: Kaboom27,
        title: 'IMPRO SHOW -KABOOM UNLIMITED IMPRO 27 ',
        hour: '20:00',
        date: '22',
        month: 'AUGUST',
        price: 'Suport Artisti: 40RON adulti, 30RON copii',
        description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
      },
      {
        id: 37,
        src: Bikeathon,
        title: 'BIKEATHON - PEDALEZ EXPLOZIV',
        hour: '',
        date: '14-15',
        month: 'SEPTEMBRIE',
        price: '',
        address: 'Targu Mures',
        decription: 'INCUBATOR13 lansează o provocare - să adunăm bani pt proiectul ExplozivART ediția 2024 în cadrul evenimentului Bikeathon. Donează sau înscrie-te la pedalat și devii ambasadorul nostru. Răspândește vestea între prieteni și haideți să avem 2 zile - 14/15 septembrie pline de mișcare, voie bună și altruism.',
        descriptio1: 'Program Bikeathon',
        description2: 'Adulți - sâmbătă și duminică 10:00 - 16:00 - traseu City și Wild',
        description3: 'Copii - duminică 10:00 - 13:00 - traseu Junior',
        description4: 'Critical Mass - duminică 14:00 - tur prin oraș cu toți participanții, la final extragere tombolă.',
        link: "https://bikeathon.ms/projects/42",
    },
      {
        id: 38,
        src: Tango,
        title: 'TANGO - SI ALTE ECOURI DE LUNA',
        hour: '20:00',
        date: '20',
        month: 'SEPTEMBRIE',
        address: 'Bastionul Porții, Cetatea medievală',
        price: 'Suport Artisti: 50 RON Adulti, 30 RON Copii',
        description: 'Un prilej rar de a ne intalni cu artistii bucuresteni Alexandru Nuca si Maxim Belciug, care vin in spatiul nostru independent, cu chitarele si bandoneonul, aducand cu ei tango, milonga, valsuri, romante de cartier si ecouri de luna. Muzica unui mod de viata cu parfum de mare, de soare, de visuri senzuale ori sarate, pierdute in vreun port, odata cu apusul...'
      },
      {
        id: 39,
        src: Kaboom28,
        title: 'IMPRO SHOW - KABOOM UNLIMITED IMPRO 28 ',
        hour: '20:00',
        date: '26',
        month: 'OCTOMBRIE',
        address: 'Bastionul Porții, Cetatea medievală',
        price: 'Suport Artisti: 40RON adulti, 30RON copii',
        description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
      },
      {
        id: 40,
        src: Kaboom30,
        title: 'IMPRO SHOW - KABOOM UNLIMITED IMPRO 30 ',
        hour: '19:30',
        date: '30',
        month: 'NOIEMBRIE',
        address: 'Bastionul Porții, Cetatea medievală',
        price: 'Suport Artisti: 40RON adulti, 30RON copii',
        description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
      },
      {
        id: 41,
        src: PisicaVerdeN,
        title: 'Niste Zile Ciudate Cu Pisica Verde ',
        hour: '18:00',
        date: '6',
        month: 'DECEMBRIE',
        address: 'Teatrul Ariel',
        price: 'INTRAREA LIBERĂ',
        description: 'Avem, din nou, o echipă de adolescenți minunati pregătiți să vă “călătorească” în lumea lor complicată. Prin adaptarea textelelor Pisica verde și Niște zile ciudate de Elise Wilk, parcurgem planurile acestor texte care sunt multiple - evadarea  din viaţa reală într-una imaginară, probleme în familie, bullying-ul pe retele, prima iubire, relațiile dintre adolescenți, anxietatea si depresia',
        description2: "Textul e gândit ca un puzzle care se compune pas cu pas, personajele alunecă între comunicarea directă și monoloage, încercând să creeze o conexiune între lumile lor.",
        descritpion3: 'Sensibilitatea acestor adolescenţi, problemele lor, întrebările, răspunsurile, tăcerile, acţiunile sau alegerile lor se releva pas cu pas si ne permit sa intram in lumea in care ei exista zi de zi. La finalul fiecarei reprezentatii, va invitam la o discutie  pe temele regasite in text, care va fi moderata de psihologul Irina Săcuiu.',
    },
      {
        id: 42,
        src: PisicaVerdeN,
        title: 'Niste Zile Ciudate Cu Pisica Verde ',
        hour: '18:00',
        date: '7',
        month: 'DECEMBRIE',
        address: 'Teatrul Ariel',
        price: 'INTRAREA LIBERĂ',
        description: 'Avem, din nou, o echipă de adolescenți minunati pregătiți să vă “călătorească” în lumea lor complicată. Prin adaptarea textelelor Pisica verde și Niște zile ciudate de Elise Wilk, parcurgem planurile acestor texte care sunt multiple - evadarea  din viaţa reală într-una imaginară, probleme în familie, bullying-ul pe retele, prima iubire, relațiile dintre adolescenți, anxietatea si depresia',
        description2: "Textul e gândit ca un puzzle care se compune pas cu pas, personajele alunecă între comunicarea directă și monoloage, încercând să creeze o conexiune între lumile lor.",
        descritpion3: 'Sensibilitatea acestor adolescenţi, problemele lor, întrebările, răspunsurile, tăcerile, acţiunile sau alegerile lor se releva pas cu pas si ne permit sa intram in lumea in care ei exista zi de zi. La finalul fiecarei reprezentatii, va invitam la o discutie  pe temele regasite in text, care va fi moderata de psihologul Irina Săcuiu.',
      },
      {
        id: 43,
        src: PisicaVerdeN,
        title: 'Niste Zile Ciudate Cu Pisica Verde ',
        hour: '18:00',
        date: '8',
        month: 'DECEMBRIE',
        address: 'Bastionul Porții, Cetatea medievală',
        price: 'INTRAREA LIBERĂ',
        description: 'Avem, din nou, o echipă de adolescenți minunati pregătiți să vă “călătorească” în lumea lor complicată. Prin adaptarea textelelor Pisica verde și Niște zile ciudate de Elise Wilk, parcurgem planurile acestor texte care sunt multiple - evadarea  din viaţa reală într-una imaginară, probleme în familie, bullying-ul pe retele, prima iubire, relațiile dintre adolescenți, anxietatea si depresia',
        description2: "Textul e gândit ca un puzzle care se compune pas cu pas, personajele alunecă între comunicarea directă și monoloage, încercând să creeze o conexiune între lumile lor.",
        descritpion3: 'Sensibilitatea acestor adolescenţi, problemele lor, întrebările, răspunsurile, tăcerile, acţiunile sau alegerile lor se releva pas cu pas si ne permit sa intram in lumea in care ei exista zi de zi. La finalul fiecarei reprezentatii, va invitam la o discutie  pe temele regasite in text, care va fi moderata de psihologul Irina Săcuiu.',
      },
      {
        id: 44,
        src: PisicaVerdeN,
        title: 'Niste Zile Ciudate Cu Pisica Verde ',
        hour: '18:00',
        date: '10',
        month: 'DECEMBRIE',
        address: 'Teatrul Ariel',
        price: 'INTRAREA LIBERĂ',
        description: 'Avem, din nou, o echipă de adolescenți minunati pregătiți să vă “călătorească” în lumea lor complicată. Prin adaptarea textelelor Pisica verde și Niște zile ciudate de Elise Wilk, parcurgem planurile acestor texte care sunt multiple - evadarea  din viaţa reală într-una imaginară, probleme în familie, bullying-ul pe retele, prima iubire, relațiile dintre adolescenți, anxietatea si depresia',
        description2: "Textul e gândit ca un puzzle care se compune pas cu pas, personajele alunecă între comunicarea directă și monoloage, încercând să creeze o conexiune între lumile lor.",
        descritpion3: 'Sensibilitatea acestor adolescenţi, problemele lor, întrebările, răspunsurile, tăcerile, acţiunile sau alegerile lor se releva pas cu pas si ne permit sa intram in lumea in care ei exista zi de zi. La finalul fiecarei reprezentatii, va invitam la o discutie  pe temele regasite in text, care va fi moderata de psihologul Irina Săcuiu.',
      },
      {
        id: 45,
        src: PisicaVerdeN,
        title: 'Niste Zile Ciudate Cu Pisica Verde ',
        hour: '18:00',
        date: '14',
        month: 'DECEMBRIE',
        address: 'Bastionul Porții, Cetatea medievală',
        price: 'INTRAREA LIBERĂ',
        description: 'Avem, din nou, o echipă de adolescenți minunati pregătiți să vă “călătorească” în lumea lor complicată. Prin adaptarea textelelor Pisica verde și Niște zile ciudate de Elise Wilk, parcurgem planurile acestor texte care sunt multiple - evadarea  din viaţa reală într-una imaginară, probleme în familie, bullying-ul pe retele, prima iubire, relațiile dintre adolescenți, anxietatea si depresia',
        description2: "Textul e gândit ca un puzzle care se compune pas cu pas, personajele alunecă între comunicarea directă și monoloage, încercând să creeze o conexiune între lumile lor.",
        descritpion3: 'Sensibilitatea acestor adolescenţi, problemele lor, întrebările, răspunsurile, tăcerile, acţiunile sau alegerile lor se releva pas cu pas si ne permit sa intram in lumea in care ei exista zi de zi. La finalul fiecarei reprezentatii, va invitam la o discutie  pe temele regasite in text, care va fi moderata de psihologul Irina Săcuiu.',
      },
      {
        id: 46,
        src: change,
        title: 'Niste Zile Ciudate Cu Pisica Verde ',
        hour: '18:00',
        date: '19',
        month: 'DECEMBRIE',
        address: 'Bastionul Porții, Cetatea medievală',
        price: 'INTRAREA LIBERĂ',
        
        description: 'Avem, din nou, o echipă de adolescenți minunati pregătiți să vă “călătorească” în lumea lor complicată. Prin adaptarea textelelor Pisica verde și Niște zile ciudate de Elise Wilk, parcurgem planurile acestor texte care sunt multiple - evadarea  din viaţa reală într-una imaginară, probleme în familie, bullying-ul pe retele, prima iubire, relațiile dintre adolescenți, anxietatea si depresia',
        description2: "Textul e gândit ca un puzzle care se compune pas cu pas, personajele alunecă între comunicarea directă și monoloage, încercând să creeze o conexiune între lumile lor.",
        descritpion3: 'Sensibilitatea acestor adolescenţi, problemele lor, întrebările, răspunsurile, tăcerile, acţiunile sau alegerile lor se releva pas cu pas si ne permit sa intram in lumea in care ei exista zi de zi. La finalul fiecarei reprezentatii, va invitam la o discutie  pe temele regasite in text, care va fi moderata de psihologul Irina Săcuiu.',
    
      },
      {
        id: 47,
        src: Kaboom31,
        title: 'IMPRO SHOW - KABOOM UNLIMITED IMPRO 31 - CHRISTMAS EDITION ',
        hour: '19:30',
        date: '20',
        month: 'DECEMBRIE',
        price: 'Suport Artisti: 40RON adulti, 30RON copii',
        address: 'Bastionul Porții, Cetatea medievală',
        description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
      }, 
      {
        id: 48,
        src: PisicaVerdeN,
        title: 'Niste Zile Ciudate Cu Pisica Verde ',
        hour: '18:00',
        date: '18',
        month: 'IANUARIE',
        address: 'Bastionul Porții, Cetatea medievală',
        price: 'Suport Artisti:  30RON adulti & 15RON copii',
        description: 'Avem, din nou, o echipă de adolescenți minunati pregătiți să vă “călătorească” în lumea lor complicată. Prin adaptarea textelelor Pisica verde și Niște zile ciudate de Elise Wilk, parcurgem planurile acestor texte care sunt multiple - evadarea  din viaţa reală într-una imaginară, probleme în familie, bullying-ul pe retele, prima iubire, relațiile dintre adolescenți, anxietatea si depresia',
        description2: "Textul e gândit ca un puzzle care se compune pas cu pas, personajele alunecă între comunicarea directă și monoloage, încercând să creeze o conexiune între lumile lor.",
        description3: 'Sensibilitatea acestor adolescenţi, problemele lor, întrebările, răspunsurile, tăcerile, acţiunile sau alegerile lor se releva pas cu pas si ne permit sa intram in lumea in care ei exista zi de zi. La finalul fiecarei reprezentatii, va invitam la o discutie  pe temele regasite in text, care va fi moderata de psihologul Irina Săcuiu.',
    
      },
      {
        id: 49,
        src: PisicaVerdeN,
        title: 'Niste Zile Ciudate Cu Pisica Verde ',
        hour: '18:00',
        date: '19',
        month: 'IANUARIE',
        address: 'Bastionul Porții, Cetatea medievală',
        price: 'Suport Artisti:  30RON adulti & 15RON copii',
        description: 'Avem, din nou, o echipă de adolescenți minunati pregătiți să vă “călătorească” în lumea lor complicată. Prin adaptarea textelelor Pisica verde și Niște zile ciudate de Elise Wilk, parcurgem planurile acestor texte care sunt multiple - evadarea  din viaţa reală într-una imaginară, probleme în familie, bullying-ul pe retele, prima iubire, relațiile dintre adolescenți, anxietatea si depresia',
        description2: "Textul e gândit ca un puzzle care se compune pas cu pas, personajele alunecă între comunicarea directă și monoloage, încercând să creeze o conexiune între lumile lor.",
        descritpion3: 'Sensibilitatea acestor adolescenţi, problemele lor, întrebările, răspunsurile, tăcerile, acţiunile sau alegerile lor se releva pas cu pas si ne permit sa intram in lumea in care ei exista zi de zi. La finalul fiecarei reprezentatii, va invitam la o discutie  pe temele regasite in text, care va fi moderata de psihologul Irina Săcuiu.',
      },
      {
        id: 50,
        src: K32afis,
        title: 'IMPRO SHOW - KABOOM UNLIMITED IMPRO 32',
        hour: '19:30',
        date: '31',
        month: 'IANUARIE',
        price: 'Suport Artisti: 40RON adulti, 30RON copii',
        address: 'Bastionul Porții, Cetatea medievală',
        description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
      }, 
      {
        id: 51,
        src: PisicaVerdeN,
        title: 'Niste Zile Ciudate Cu Pisica Verde ',
        hour: '18:00',
        date: '16',
        month: 'FEBRUARIE',
        address: 'Bastionul Porții, Cetatea medievală',
        price: 'Suport Artisti:  30RON adulti & 15RON copii',
        description: 'Avem, din nou, o echipă de adolescenți minunati pregătiți să vă “călătorească” în lumea lor complicată. Prin adaptarea textelelor Pisica verde și Niște zile ciudate de Elise Wilk, parcurgem planurile acestor texte care sunt multiple - evadarea  din viaţa reală într-una imaginară, probleme în familie, bullying-ul pe retele, prima iubire, relațiile dintre adolescenți, anxietatea si depresia',
        description2: "Textul e gândit ca un puzzle care se compune pas cu pas, personajele alunecă între comunicarea directă și monoloage, încercând să creeze o conexiune între lumile lor.",
        descritpion3: 'Sensibilitatea acestor adolescenţi, problemele lor, întrebările, răspunsurile, tăcerile, acţiunile sau alegerile lor se releva pas cu pas si ne permit sa intram in lumea in care ei exista zi de zi. La finalul fiecarei reprezentatii, va invitam la o discutie  pe temele regasite in text, care va fi moderata de psihologul Irina Săcuiu.',
      },
      {
        id: 52,
        src: K33,
        title: 'IMPRO SHOW - KABOOM UNLIMITED IMPRO 33',
        hour: '19:30',
        date: '21',
        month: 'FEBRUARIE',
        price: 'Suport Artisti: 40RON adulti, 30RON copii',
        address: 'Bastionul Porții, Cetatea medievală',
        description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
      }, 
      {
        id: 53,
        src: OpenMic,
        title: 'Open Mic Poetry',
        hour: '16:30',
        date: '22',
        month: 'FEBRUARIE',
        price: 'Intrare liberă',
        address: 'Bastionul Porții, Cetatea medievală',
        description:'Open Mic Poetry a luat naștere din pasiunea pentru poezie și lipsa unui loc în care poeții amatori și-ar putea expune creațiile. Prin inițiativa Open Mic Poetry Târgu Mureș am creat acel spațiu primitor, am făurit acea fărâmă de prezent în care fiecare suflet implicat - fie el scriitor, sau doar iubitor de artă - se poate deschide, arătându-și propria viziune asupra lumii. Acest proiect este rezultatul pasiunii unei întregi comunități care este din ce în ce mai nerăbdătoare să iasă la lumină.',
      }, 
      {
        id: 54,
        src: concert,
        title: 'Concert - Air Lines',
        hour: '20:00',
        date: '14',
        month: 'Martie',
        price: '40RON',
        address: 'Bastionul Porții, Cetatea medievală',
        description:'Air Lines este o formație noise-rock cu `baza` în București, ințiată de basistul Augustin Nicolae (ex. The Amsterdams, Dekadens, Orizontal, Partizan)',
        description2: 'Rock and roll, noise, distortion, punk, post-punk… un cocktail sonic marca Air Lines, care ia cu asalt Bastionul Porții de la Târgu Mureș pe 14 martie, la Incubator 13!'
      }, 
      {
        id: 55,
        src: Suspendat,
        title: 'Niste Zile Ciudate Cu Pisica Verde - SUSPENDAT ',
        hour: '18:00',
        date: '16',
        month: 'MARTIE',
        address: 'Bastionul Porții, Cetatea medievală',
        price: 'Suport Artisti:  30RON adulti & 15RON copii',
        description: 'Avem, din nou, o echipă de adolescenți minunati pregătiți să vă “călătorească” în lumea lor complicată. Prin adaptarea textelelor Pisica verde și Niște zile ciudate de Elise Wilk, parcurgem planurile acestor texte care sunt multiple - evadarea  din viaţa reală într-una imaginară, probleme în familie, bullying-ul pe retele, prima iubire, relațiile dintre adolescenți, anxietatea si depresia',
        description2: "Textul e gândit ca un puzzle care se compune pas cu pas, personajele alunecă între comunicarea directă și monoloage, încercând să creeze o conexiune între lumile lor.",
        descritpion3: 'Sensibilitatea acestor adolescenţi, problemele lor, întrebările, răspunsurile, tăcerile, acţiunile sau alegerile lor se releva pas cu pas si ne permit sa intram in lumea in care ei exista zi de zi. La finalul fiecarei reprezentatii, va invitam la o discutie  pe temele regasite in text, care va fi moderata de psihologul Irina Săcuiu.',
      },
      {
        id: 56,
        src: Improteka,
        title: 'Improteka -  Un Show de Impro original cu adolescenti',
        hour: '19:00',
        date: '21',
        month: 'MARTIE',
        address: 'Bastionul Porții, Cetatea medievală',
        price: 'Intrarea libera',
        description: 'spectacol invitat, trupa de elevi a Alexandrei Moldovan',
      },
    {
        id: 57,
        src: exp,
        title: ' Spectacol Exploziv - PREMIERA',
        hour: '',
        date: '23',
        month: 'MARTIE',
        price: 'Adulti > 18 ani:  30RON & Adulti <  18 ani: 15RON',
        address: 'Bastionul Porții, Cetatea medievală',
        description6: 'Exploziv - Este un spectacol care reflectă probleme ale adolescenților:  de la conflictele lor interioare până la conflictele cu cei din jur, nevoia lor de a ieși în evidență, de fi văzuți, ascultați, valorizați, nevoia de suport parental și psiho-educațional. Este o lecție despre viață, despre dorințe neîmplinite, despre drame umane, care te îndeamnă la reflecție. Exploziv -  un spectacol actual care ar trebui văzut de adolescenți, părinți, profesori și nu numai.',
        description7: '23 Martie ora 18:00 Premieră',
    },
    {
        id: 58,
        src: Concert23,
        title: 'Concert',
        hour: '20:00',
        date: '23',
        month: 'MARTIE',
        price: 'Adulti > 18 ani:  30RON & Adulti <  18 ani: 10RON',
        address: 'Bastionul Porții, Cetatea medievală',
        description: 'Cvartetul Dzingisz este o trupă formată din adolescenți coordonați de  SégerczFeri. Își datorează creația Taberei de Jazz și muzică  improvizativă din Sfântu Gheorghe, unde au învățat jazz classic de la marii profesori de jazz din Ungaria (Tibor Márkus, Viktor Tóth, György Jeszenszky, Gábor Juhász, Ferenc Kovács.). La concertele lor regăsim un mix de muzică modernă de jazz și muzică tradițională populară.',
        description2: ' Membrii trupei: Czegő Abel- tobe, Balázs Némethi - chitară bass, Jakab Dániel- pian, Metz Gyurka Iringó - vioară, voce, fluier ciobanesc, Ferenc Ségercz - instrumente de suflat tradiționale, saxofon ', 
        description3: 'A Dzsingisz quartett egy tinédzserekből álló zenekar SégerczFeri őrködő jelenlétével . Létrejöttét egyrészt a sepsiszentgyörgy Jazz És Improvizációs Zenei Tábornak köszönheti.  Itt a hagyományos jazzmuzsikát tanulják folyamatosan a magyar jazz nagy oktatóitól, (Márkus Tibor, Tóth Viktor, Jeszenszky György, Juhász Gábor, Kovács Ferenc.). Másrészt a Ségerczferi táboraiban születnek a sajátos szerzemények melyeket a népzenei alapokból kiindulva raknak össze. Koncertjeiken a modern zenei hangzás és a népzene találkozik. A népviseletet nem magukon, hanem belül a szívekbe hordják.',
        description4: 'A zenekar tagjai:Czegő Ábel -dobok, Némethi Balázs- basszusgitár,Jakab Dániel- zongora, Metz Gyurka Iringó- hegedű, ének, pásztorfurulya,Ségercz Ferenc - népi fúvósok    ',
        description5: '23 Martie , ora 20:00 ',
    },
      {
        id: 59,
        src: K34,
        title: 'IMPRO SHOW - KABOOM UNLIMITED IMPRO 34',
        hour: '19:30',
        date: '28',
        month: 'MARTIE',
        price: 'Suport Artisti: 40RON adulti, 30RON copii',
        address: 'Turnul Porții, Cetatea medievală',
        description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
      },
      {
        id: 60,
        src: Suspendat6,
        title: 'Niste Zile Ciudate Cu Pisica Verde',
        hour: '18:00',
        date: '6',
        month: 'APRILIE',
        price: 'Suport Artisti:  30RON adulti & 15RON copii',
        address: 'Turnul Porții, Cetatea medievală',
        description: 'Avem, din nou, o echipă de adolescenți minunati pregătiți să vă “călătorească” în lumea lor complicată. Prin adaptarea textelelor Pisica verde și Niște zile ciudate de Elise Wilk, parcurgem planurile acestor texte care sunt multiple - evadarea  din viaţa reală într-una imaginară, probleme în familie, bullying-ul pe retele, prima iubire, relațiile dintre adolescenți, anxietatea si depresia',
        description2: "Textul e gândit ca un puzzle care se compune pas cu pas, personajele alunecă între comunicarea directă și monoloage, încercând să creeze o conexiune între lumile lor.",
        descritpion3: 'Sensibilitatea acestor adolescenţi, problemele lor, întrebările, răspunsurile, tăcerile, acţiunile sau alegerile lor se releva pas cu pas si ne permit sa intram in lumea in care ei exista zi de zi. La finalul fiecarei reprezentatii, va invitam la o discutie  pe temele regasite in text, care va fi moderata de psihologul Irina Săcuiu.',
    },
    {
        id: 61,
        src: K35,
        title: 'IMPRO SHOW - KABOOM UNLIMITED IMPRO 35',
        hour: '19:30',
        date: '25',
        month: 'APRILIE',
        price: 'Suport Artisti: 40RON adulti, 30RON copii',
        address: 'Turnul Porții, Cetatea medievală',
        description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
    },
    {
        id: 62,
        src: Exploziv27,
        title: ' Spectacol Exploziv de Elise Wilk',
        hour: '18:00',
        date: '27',
        month: 'APRILIE',
        price: 'Adulti > 18 ani:  30RON & Adulti <  18 ani: 15RON',
        address: 'Bastionul Porții, Cetatea medievală',
        description6: 'Exploziv - Este un spectacol care reflectă probleme ale adolescenților:  de la conflictele lor interioare până la conflictele cu cei din jur, nevoia lor de a ieși în evidență, de fi văzuți, ascultați, valorizați, nevoia de suport parental și psiho-educațional. Este o lecție despre viață, despre dorințe neîmplinite, despre drame umane, care te îndeamnă la reflecție. Exploziv -  un spectacol actual care ar trebui văzut de adolescenți, părinți, profesori și nu numai.',
    },
  ]
  