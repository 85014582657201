import React from 'react';
import { Grid, Image, Segment, Icon } from 'semantic-ui-react';
import Logo from '../../utils/GeneralImages/Logo.png';
import './Footer.css'; 

import { primaryGreen, primaryGrey } from '../../utils/colors';

import { useMediaQuery } from 'react-responsive';

import { 
    headingFontSize3,
    headingFontSize5,
    headingFontSize2,
    headingFontSize6,
    mediumSize2,
    mediumSize3,
    mediumSize5,
    mediumSize6
} from '../../utils/fonts';

const Footer = () => {

    const none =useMediaQuery({ query: "(max-width:800px)" }) 
    const sm = useMediaQuery({ query: "(min-width:600px)" })
    const md = useMediaQuery({ query: "(min-width:400px)" })
    const xl = useMediaQuery({ query: "(min-width:650px)" })
    const xxl = useMediaQuery({ query: "(min-width:900px)" })
    const xxs = useMediaQuery({ query: "(max-width:380px)" })
  
    const size = {none,sm,md,xl,xxl, xxs}


  return (
        <Grid columns={none ? 2 : 3} className="footer-grid" style={{margin: 'auto', backgroundColor: primaryGreen, paddingBottom: '1rem'}}>
            <Grid.Column style={{ backgroundColor: primaryGreen}}>
                <Image src={Logo} alt="Logo" size={xxs ? "tiny" : 'small'} style={{marginLeft: '2rem'}} />
            </Grid.Column>


            {!none ?
            <Grid.Column style={{ backgroundColor: primaryGreen}}>
                <Segment.Group 
                    className="footer-segment-group" 
                    style={{ backgroundColor: primaryGreen, border: 0, boxShadow: 'none'}}
                >

                    <Segment 
                        className="footer-segment" 
                        style={{
                            ...secondCol, 
                            fontSize: 
                            xxl ? headingFontSize2 : 
                            xl ?  headingFontSize3 : 
                            headingFontSize5
                        }}
                    >
                        ADRESA
                    </Segment>

                    <Segment 
                        className="footer-segment" 
                        style={{ 
                            ...secondCol, 
                            fontSize: xxl ? mediumSize2 : 
                            mediumSize6
                        }}
                    >
                        Strada Avram Iancu, Bastionul Porții
                    </Segment>

                    <Segment 
                        className="footer-segment" 
                        style={{ 
                            ...secondCol, 
                            fontSize: xxl ? mediumSize2 : 
                            mediumSize6
                        }}
                    >
                        Cetatea Medievală Târgu Mureș
                    </Segment>

                    <Segment 
                        className="footer-segment" 
                        style={{ 
                            ...secondCol, 
                            fontSize: xxl ? mediumSize2 : 
                            mediumSize6
                        }}
                    > 
                        TELEFON: +40 723 314 899
                    </Segment>

                </Segment.Group>
            </Grid.Column>

            : null }




            <Grid.Column style={{ backgroundColor: primaryGreen}}>
                <Segment.Group className="footer-segment-group" style={{border: 0, boxShadow: 'none'}}>
                    
                    <Segment className="footer-segment" 
                        style={{ 
                            backgroundColor: primaryGreen, 
                            border: 0, 
                            boxShadow: 'none', 
                            height: xxl ? '5rem' :'7rem', 
                            fontSize: xl ? headingFontSize3 : md ? headingFontSize5 : headingFontSize6, 
                            color: primaryGrey,
                            marginBottom: sm ? '-15px' : '0px'
                        }}
                    >
                        Ne poți găsi pe următoarele rețele de socializare:
                    </Segment>

                    <Segment className="footer-icon-segment" style={{ backgroundColor: primaryGreen, border: 0, boxShadow: 'none', height: '1rem'}}>
                        <Icon 
                            name='facebook' 
                            size={sm ? 'big' : 'large'}
                            style={{backgroundColor: primaryGreen, cursor: 'pointer'}}
                            onClick={() => window.open('https://www.facebook.com/incubator13treishpe', '_blank')}
                        />
                        <Icon 
                            name='instagram' 
                            size={sm ? 'big' : 'large'}
                            style={{backgroundColor: primaryGreen, cursor: 'pointer'}}
                            onClick={() => window.open('https://www.instagram.com/incubator.13/', '_blank')}
                        />
                    </Segment>

                </Segment.Group>
            </Grid.Column>
        </Grid>
  );
};

export default Footer;


const secondCol = {
    backgroundColor: primaryGreen,
    border: 0,
    boxShadow: 'none',
    height: '1rem',
    color: primaryGrey,
}