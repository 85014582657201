
import HeaderComponent from "../../components/Header/Header";

import { primaryGrey } from "../../utils/colors";
import Footer from "../../components/Footer/Footer";
import { useParams } from 'react-router-dom';
import { allShows } from "../../utils/dummyShows";
import { Icon } from 'semantic-ui-react';

import { useMediaQuery } from 'react-responsive';

import ImageCarousel from '../Homepage/ImageCarousel/ImageCarousel';

import './EvenimenteSinglePage.css';


const EvenimenteSinglePage = () => {


    let { id } = useParams();
    const showData = allShows.find(show => show.id == id);

    const galleryData = showData ? showData?.gallery : null;

    const xxl = useMediaQuery({ query: "(max-width:900px)" })


    

    return (
        <>
            <HeaderComponent />
            
            <div 
                style={{
                    backgroundColor: primaryGrey,
                    display: xxl? '' : 'flex',
                    width: '80%',
                    margin: 'auto',
                    marginBottom: '20vh',
                    // height: xxl ? '74vh' : '63.7vh',
                    marginTop: '5rem'
                }}
            >

                <div className="left">
                   <img  src={showData?.src} alt={showData?.title} height={340} width={340} style={{marginRight: '3rem'}}/>
                </div>

                <div className="right" >
                   <p className="title">{showData?.title}</p>
                   <p className="description-a"> {showData?.description}</p>
                   <p className="description-a"> {showData?.description1}</p>
                   <p className="description-a"> {showData?.description2}</p>
                   <p className="description-a"> {showData?.description3}</p>
                   <p className="description-a"> {showData?.description4}</p>
                   <p className="description-b"> {showData?.description5}</p>
                   <p className="description-a"> {showData?.description6}</p>
                   <p className="description-b"> {showData?.description7}</p>
                   {/* <p className="description-a" style={{marginBottom: '4rem'}}> {showData?.description2}</p> */}


                   <div className="dates">
                    <span>{showData?.hour}</span>
                    <span>{showData?.date}  {showData?.month}</span>
                   </div>


                    <div className="address">
                        <Icon name='map marker alternate icon' size='big' style={{backgroundColor: primaryGrey}}/>
                        <span>{showData?.address}</span>
                    </div>

                    {showData?.price &&
                        <div className="pay">
                            <Icon name='money bill alternate outline icon' size='big' style={{backgroundColor: primaryGrey}}/>
                            <span>{showData?.price}</span>
                        </div>
                    }

                    {showData?.link &&
                        <div className="pay">
                            <Icon name='money bill alternate outline icon' size='big' style={{backgroundColor: primaryGrey}}/>
                            <span> <a href={showData?.link}>Inscrie-te aici: {showData?.link}</a></span>
                        </div>
                    }


                    <p className="rez">Rezervari: <span className="mail">incubator13ro@gmail.com</span></p>

                    
                   
                </div>
            </div>
            

        {galleryData ? 
            <ImageCarousel data={galleryData}/>  
        : null }

            <Footer />
        </>
    )
}

export default EvenimenteSinglePage;